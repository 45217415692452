import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ForgotPasswordBody } from '../models/password.model';
import { FeedbackService } from '../../../shared/services/feedback.service';
import { ForgotPasswordService } from '../services/forgot-password.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinner
],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent implements OnInit {
  form!: FormGroup;
  hide = true;
  isLoading: boolean = false;
  isSend: boolean = false;
  body: ForgotPasswordBody = {};

  constructor(
    private formBuilder: FormBuilder,
    private forgotPasswordService: ForgotPasswordService,
    private feedbackService: FeedbackService
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void { }

  onSend(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    const rawValue = this.form.getRawValue();
    this.body.email = rawValue.email;
    this.send(this.body);
  }

  resend(): void {
    this.send(this.body);
  }

  private send(body: ForgotPasswordBody): void {
    this.isLoading = true;
    this.forgotPasswordService.forgot(body).subscribe({
      next: (res) => {
        if (res) {
          if (res.success) {
            this.isSend = true;
          } else {
            this.feedbackService.error(res.message);
          }
          this.isLoading = false;
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.feedbackService.httpError(err);
      },
    });
  }
}
