import { HttpInterceptorFn } from '@angular/common/http';
import { CredentialsService } from '../../modules/auth/services/credentials.service';
import { inject } from '@angular/core';

export const authInterceptorInterceptor: HttpInterceptorFn = (req, next) => {
  let credentials: CredentialsService = inject(CredentialsService);
  const token = credentials.getToken();

  // if (!token) {
  //   return next(req);
  // }

  // const req1 = req.clone({
  //   headers: req.headers.set('Authorization', `Bearer ${token}`),
  // });

  // return next(req1);
  if (typeof token === 'string') {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `${token}`),
    });
    return next(authReq);
  }
  return next(req);
  // credentials = inject(CredentialsService);

  // credentials.
  // const authToken = 'YOUR_AUTH_TOKEN_HERE';

  // // Clone the request and add the authorization header
  // const authReq = req.clone({
  //   setHeaders: {
  //     Authorization: `Bearer ${authToken}`,
  //   },
  // });

  // // Pass the cloned request with the updated header to the next handler
  // return next(authReq).pipe(
  //   catchError((err: any) => {
  //     // Remove caught: HttpResponse
  //     if (err instanceof HttpErrorResponse) {
  //       // Handle HTTP errors
  //       if (err.status === 401) {
  //         // Specific handling for unauthorized errors
  //         console.error('Unauthorized request:', err);
  //         // You might trigger a re-authentication flow or redirect the user here
  //       } else {
  //         // Handle other HTTP error codes
  //         console.error('HTTP error:', err);
  //       }
  //     } else {
  //       // Handle non-HTTP errors
  //       console.error('An error occurred:', err);
  //     }

  //     // Re-throw the error to propagate it further
  //     return throwError(() => err);
  //   })
  // );
};
